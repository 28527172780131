(function ($) {
    let responseCRM;

    /*$.fn.simulateClick = function() {
        return this.each(function() {
            if('createEvent' in document) {
                let doc = this.ownerDocument,
                    evt = doc.createEvent('MouseEvents');
                evt.initMouseEvent('click', true, true, doc.defaultView, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                this.dispatchEvent(evt);
                console.log(doc);
            } else {
                this.click(); // IE
            }
        });
    };*/

    function errorSwal() {
        Swal.fire(
            'Упс ((',
            'Что-то пошло не так, сообщите пожалуйста об этом администратору сайта.',
            'error'
        )
    }

    $(function () {
        function sendToCRM(data, sender, dataPay, btn) {
            $.ajax({
                url: window.wp_data.ajax_url,
                type: 'POST',
                dataType: 'json',
                data: {
                    action: 'send_crm',
                    data: data,
                    security: window.wp_nonce
                },
            })
                .done(function (response) {
                    function parseResponseCRM(crm) {
                        console.info(crm);
                    }

                    parseResponseCRM(response.data.crm);
                    if (sender.free === true) {
                        Swal.fire({
                            title: 'Спасибо за регистрацию!',
                            html: 'Запись на бесплатный вебинар прошла успешно.',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showCloseButton: true,
                        }).then((result) => {
                            console.log(result, dataPay);
                            if (result.value === true) {
                                window.location = dataPay.formUrl;
                            } else {
                                $('body').find('.mfp-content').trigger("click");
                            }
                        });
                    } else {
                        Swal.fire({
                            title: 'Спасибо за вашу заявку!',
                            html: 'Вы можете оплатить вебинар сейчас или подождать, пока с вами свяжется наш менеджер.',
                            type: 'success',
                            showCancelButton: false,
                            showCloseButton: true,
                            confirmButtonColor: '#1f936d',
                            confirmButtonText: 'Оплатить сейчас!',
                        }).then((result) => {
                            if (result.value === true) {
                                window.location = dataPay.formUrl;
                            } else {
                                $('body').find('.mfp-content').trigger("click");
                            }
                        });
                    }
                })
                .fail(function () {
                    console.log("error");
                })
                .always(function (response) {
                    btn.removeAttr('disabled');
                });
        }

        $('#payment-form').on('submit', function (event) {
            event.preventDefault();
            let $this = $(this),
                data = $this.serializeArray(),
                btn = $this.find('input[type="submit"]');
            console.log(data);

            let phone = $('#payment_MobilePhone'),
                phoneValue = phone.val(),
                placeholder = phone.attr('placeholder');

            /*let phoneValue = '864231654988',
                placeholder = '+,_,_,(,_,_,_,),_,_,_,_,-,_,_,_',
                data = {test:1, phone:2, userName:3};*/

            if (phoneValue.length > 0) {
                for (let i = 0; i < phoneValue.length; i++) {
                    placeholder = placeholder.replace(',_', phoneValue[i])
                }
                placeholder = placeholder.replace(/,/g, '');
            }

            for (let prop in data) {
                if (data[prop]['name'] === 'phone') {
                    data[prop]['value'] = placeholder;
                }
            }
            $this.find('.errors-elem').text("");
            btn.attr('disabled', 'disabled');

            $.ajax({
                type: "POST",
                dataType: 'json',
                url: window.wp_data.ajax_url,
                data: {
                    action: 'payment_form',
                    data: data,
                    security: window.wp_nonce
                },
            })
                .done(function (response) {
                    let sender = response.data.sender,
                        dataPay = response.data.dataPay,
                        dataForm = response.data.info;

                    if (sender.errors !== null) {
                        sender.errors.forEach(function (elem, index) {
                            $this.find('.' + elem.type + '-group .errors-elem').html(elem.message);
                        });
                    } else if (sender.errors === null && sender.send === true) {
                        sendToCRM(dataForm, sender, dataPay, btn);
                    } else {
                        errorSwal();
                    }
                })
                .fail(function (response) {
                    console.log("error");
                    errorSwal();
                })
                .always(function () {
                    console.log("complete");
                    btn.removeAttr('disabled');
                });
        });
    });

    $(".js-site-nav li.menu-item").removeClass("current");
    let fullTimeMenu = $('#menu-item-66455'),
        distanceMenu = $('#menu-item-66469'),
        fullTimeSubmenu = fullTimeMenu.find('.current-page-ancestor'),
        distanceTimeSubmenu = distanceMenu.find('.current-page-ancestor');

    (function () {
        if (fullTimeSubmenu.length && distanceTimeSubmenu.length) {
            fullTimeMenu.removeClass('current-page-ancestor');
            distanceMenu.addClass('current-page-ancestor');
        }

        if (fullTimeMenu.hasClass('current-page-ancestor') && distanceMenu.hasClass('current_page_ancestor')) {
            fullTimeMenu.removeClass('current-page-ancestor');
            fullTimeMenu.removeClass('current-active');
        }

        $('.current-page-ancestor').addClass('current_page_ancestor');
    })();

    $(".js-site-nav li.menu-item a").each(function (index, elem) {
        let $this = $(elem),
            pathname = window.location.pathname,
            href = $(elem).attr("href"),
            pattern = new RegExp(pathname + "$"),
            regExp = href.match(pattern);

        if (!regExp || pathname === "/") return;

        if (regExp[0] === "/study/distance/") {
            fullTimeMenu.removeClass('current-page-ancestor');
            fullTimeMenu.removeClass('current_page_ancestor');
            distanceMenu.addClass('current-active');
        } else {
            $this.parent("li").addClass("current-active");
            $this.parents('.current-menu-parent').addClass('current-active');

            if (fullTimeSubmenu.length && distanceTimeSubmenu.length) {
            } else {
                $('.js-site-nav > ul > li').each(function (index, elem) {
                    let $this = $(elem);
                    if ($this.find('.sub-menu > .current-active').length) {
                        $this.addClass('current-page-ancestor');
                    }
                });
            }
        }
    });

    var carousel = $('.gallery__whaterwheel-slider').waterwheelCarousel({
        flankingItems: 2,
        separation: 110,
        forcedImageWidth: 320,
        forcedImageheight: 120,
    });
    $('.gallery__whaterwheel-slider').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        preloader: true,
        gallery: {enabled: true}
    });
    $(".gallery__whaterwheel-nav .nav-prev").on("click", function (e) {
        e.preventDefault()
        carousel.prev()
    });
    $(".gallery__whaterwheel-nav .nav-next").on("click", function (e) {
        e.preventDefault()
        carousel.next()
    });
    $(".gallery__list-slider").slick({
        infinite: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 671,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            }
        ]
    });
    $(".gallery__list .gallery-list__item.modal-link a").on("click", function (e) {
        e.preventDefault()
        e.stopPropagation();
        $(".gallery__list").addClass("hide")
        $(".gallery__modal").addClass("show")
        $("body, html").addClass("modal-opened")
    });
    $(window).click(function () {
        $(".gallery__list").removeClass("hide")
        $(".gallery__modal").removeClass("show")
        $("body, html").removeClass("modal-opened")
    });
    $(document).keydown(function (event) {
        if (event.keyCode == 27) {
            $(".gallery__list").removeClass("hide")
            $(".gallery__modal").removeClass("show")
            $("body, html").removeClass("modal-opened")
        }
    });
    $(".gallery__modal").on("click", function (e) {
        e.stopPropagation();
    });
    $(".gallery__modal a.close").on("click", function (e) {
        e.preventDefault()
        $(".gallery__list").removeClass("hide")
        $(".gallery__modal").removeClass("show")
        $("body").removeClass("modal-opened")
    });

    $('.gallery__modal-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.gallery__modal-nav'
    });
    $('.gallery__modal-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        asNavFor: '.gallery__modal-for',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true
    });
    $('.news-slider').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 2,
        centerMode: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 819,
                settings: {
                    slidesToScroll: 1,
                }
            }
        ]
    });
}(jQuery));
